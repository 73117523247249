import React, {  useState } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import GlobalStyle from './styles/global';

import { Routes, Route, useLocation, Navigate } from 'react-router-dom';

import Navbar from './components/organisms/navbar';

import HomePage from './pages/homePage';
import ProcessPage from './pages/processPage';
import Footer from './components/organisms/footer';
import ProductsPage from './pages/productsPage';
import AboutPage from './pages/aboutPage';
import ContactPage from './pages/contactPage';
import CareerPage from './pages/careerPage';
import ServicePage from './pages/servicesPage';
import CurrentJobPage from './pages/currentJobPage';
import SendCvPage from './pages/sendCvPage';
import ErrorPage from './pages/errorPage';
import AdminPage from './pages/adminPage';
import LoginPage from 'pages/loginPage';
import CarrerDetailPage from 'pages/careerDetailPage';
// import Chatbot from 'components/chatbot/chatbot';
// import AdminChat from 'components/chatbot/adminChat';
import { AuthProvider, useAuth } from './context/authContext/AuthContext';

const App = () => {
  const [selectedUserId, setSelectedUserId] = useState<string | null>('null');

  const location = useLocation();
  const excludeNavbarFooter = ['/manageCareers', '/login', '/admin', '/user'].includes(
    location.pathname,
  );

  const excludeFooter = location.pathname.includes('/careerDetails/');

  const ProtectedRoute = ({ element }: { element: JSX.Element }) => {
    const { isAuthenticated } = useAuth();

    return isAuthenticated ? element : <Navigate to="/login" replace />;
  };

  return (
    <div className="app">
      <GlobalStyle />
      {!excludeNavbarFooter && <Navbar />}
      <Routes>
        <Route path={'/'} element={<HomePage />} />
        <Route path={'/services'} element={<ServicePage />} />
        <Route path={'/process'} element={<ProcessPage />} />
        <Route path={'/products'} element={<ProductsPage />} />
        <Route path={'/aboutUs'} element={<AboutPage />} />
        <Route path={'/career'} element={<CareerPage />} />
        <Route path={'/currentJob/:id'} element={<CurrentJobPage />} />
        <Route path={'/sendCv/:id'} element={<SendCvPage />} />
        <Route path={'/contactUs'} element={<ContactPage />} />
        <Route path={'/careerDetails/:jobId'} element={<CarrerDetailPage />} />
        <Route path={'/login'} element={<LoginPage />} />
        {/* <Route
          path="/admin"
          element={
            <AdminChat selectedUserId={selectedUserId} setSelectedUserId={setSelectedUserId} />
          }
        />
        <Route path="/user" element={<Chatbot selectedUserId={selectedUserId} />} /> */}

        <Route
          path={'/manageCareers'}
          element={
            <AuthProvider>
              <ProtectedRoute element={<AdminPage />} />
            </AuthProvider>
          }
        />
        <Route path={'/pageNotFound'} element={<ErrorPage />} />
        <Route path={'*'} element={<ErrorPage />} />
      </Routes>
      {!excludeNavbarFooter && !excludeFooter && location.pathname !== '/services' ? (
        <Footer />
      ) : (
        ''
      )}
    </div>
  );
};

export default App;
