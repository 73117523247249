import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { IPositionProps } from '../careerPage/types';
import {
  Type,
  Title,
  Info,
  Category,
  Level,
  Highlight,
  Details,
  DetailItem,
  UnderLine,
  Description,
  DescriptionText,
  TextTitle,
  ListItems,
  ListItem,
  ButtonContainer,
  CvButton,
  StyledFileUploadButton,
  HiddenFileInput,
  Checkmark,
} from './careerDetailPage.styled';
import timeIcon from 'assets/photos/time.svg';
import locationIcon from 'assets/photos/location.svg';
import dateIcon from 'assets/photos/date.svg';
import attachIcon from 'assets/photos/attach.svg';
import Container from 'components/atoms/container';
import { getCareerById, attachCV } from '../../api/api';

const CarrerDetailPage = () => {
  const { jobId } = useParams();

  const [career, setCareer] = useState<any>(null); // State to store career details
  const [loading, setLoading] = useState(true); // State to manage loading state
  const [error, setError] = useState<string | null>(null); // State to handle error
  const [fileMap, setFileMap] = useState<Record<string, File | null>>({});
  const [fileUploadStatus, setFileUploadStatus] = useState<Record<string, boolean>>({});

  useEffect(() => {
    const fetchCareerDetails = async () => {
      try {
        const { data } = await getCareerById(jobId);
        setCareer(data);
      } catch (err) {
        console.log(err);
        setError('Failed to fetch career details.');
      } finally {
        setLoading(false);
      }
    };

    if (jobId) {
      fetchCareerDetails();
    }
  }, [jobId]);

  const handleUpload = async (jobId: string, data: IPositionProps) => {
    const file = fileMap[jobId];
    if (!file) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        confirmButtonColor: '#4caf50',
        text: 'Please select a file for this position.',
      });
      setFileUploadStatus(prev => ({
        ...prev,
        [jobId]: false,
      }));
      return;
    }

    try {
        
      const result = await attachCV(file, {
        type: data.type.toLowerCase(),
        position: data.position,
        level: data.level,
      });

      if (result.data.success) {
        Swal.fire({
          title: 'Good job!',
          text: 'File uploaded successfully',
          confirmButtonColor: '#4caf50',
          icon: 'success',
        });
      }

      setFileMap(prev => ({
        ...prev,
        [jobId]: null,
      }));
    } catch (error) {
      console.error(`Error uploading file for job ${jobId}:`, error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        confirmButtonColor: '#4caf50',
        text: `Error uploading file: ${error.response.data.message}`,
      });
    }
    setFileUploadStatus(prev => ({
      ...prev,
      [jobId]: false,
    }));
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>, jobId: string) => {
    const MAX_FILE_SIZE = 10 * 1024 * 1024; 
    const file = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;
    if (file && file.size > MAX_FILE_SIZE) {
        
     await Swal.fire({
        icon: 'error',
        title: 'Oops...',
        confirmButtonColor: '#4caf50',
        text: 'Error uploading file: File size exceeds the 10MB limit.',
      });
      return;
    }
    setFileMap(prev => ({
      ...prev,
      [jobId]: file,
    }));
    setFileUploadStatus(prev => ({
      ...prev,
      [jobId]: true,
    }));
  };

  console.log(career);
  if (loading) {
    return <p>Loading career details...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (!career) {
    return <p>No career details found.</p>;
  }

  const date = new Date(career.createdDate);

  const formattedDate = date
    .toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })
    .replaceAll('/', '.');

  return (
    <Container>
      <Title>{career.position}</Title>
      <Info>
        <Category>
          Category: <Type>{career.type}</Type>
        </Category>
        <Level>
          Level: <Highlight>{career.level}</Highlight>
        </Level>
      </Info>
      <Details>
        <DetailItem>
          <img src={timeIcon} alt="time icon" />
          {career.jobType}
        </DetailItem>
        <DetailItem>
          <img src={locationIcon} alt="location icon" />
          Yerevan
        </DetailItem>
        <DetailItem>
          <img src={dateIcon} alt="date icon" />
          {formattedDate}
        </DetailItem>
      </Details>
      <UnderLine />
      <Description>
        <TextTitle>Job description:</TextTitle>
        <DescriptionText>{career.jobDescription}</DescriptionText>
      </Description>
      <UnderLine />
      <Description>
        <TextTitle>Job responsibilities:</TextTitle>
        <ListItems>
          {career.responsibilities.map((item: string, index: number) => (
            <ListItem key={index}>{item}</ListItem>
          ))}
        </ListItems>
      </Description>
      <UnderLine />
      <Description>
        <TextTitle>Job qualifications:</TextTitle>
        <ListItems>
          {career.qualifications.map((item: string, index: number) => (
            <ListItem key={index}>{item}</ListItem>
          ))}
        </ListItems>
      </Description>
      <UnderLine />
      <Description>
        <TextTitle>Additional Information:</TextTitle>
        <DescriptionText>{career.additionalInfo}</DescriptionText>
      </Description>
      <StyledFileUploadButton
        onClick={() => document.getElementById(`file-input-${jobId}`)?.click()}
      >
        Upload CV
      </StyledFileUploadButton>
      <HiddenFileInput
        type="file"
        id={`file-input-${jobId}`}
        onChange={e => {
          if (jobId) {
            handleFileChange(e, jobId);
          }
        }}
      />
      {jobId && fileUploadStatus[jobId] && <Checkmark>✔</Checkmark>}
      <ButtonContainer>
        {/* <EmailButton>
          <img src={letterIcon} alt="letter icon" />
          Apply with Email
        </EmailButton> */}

        <CvButton
          onClick={() => {
            if (jobId) {
              handleUpload(jobId, career);
            }
          }}
        >
          <img src={attachIcon} alt="attach icon" />
          Apply with Attaching CV
        </CvButton>
      </ButtonContainer>
    </Container>
  );
};

export default CarrerDetailPage;
